import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { Text } from '../../redesign';

interface ItemCarouselProps {
  items: Array<{
    title: string;
    description: string;
    icon: React.ReactNode;
  }>;
}

const ItemCarousel: React.FC<ItemCarouselProps> = ({ items }) => {
  const responsive = {
    desktop_xxl: {
      breakpoint: { max: 3000, min: 2000 },
      items: 6,
      slidesToSlide: 1
    },
    desktop_xl: {
      breakpoint: { max: 2000, min: 1440 },
      items: 5,
      slidesToSlide: 1
    },
    desktop_lg: {
      breakpoint: { max: 1440, min: 1280 },
      items: 4,
      slidesToSlide: 1
    },
    desktop_md: {
      breakpoint: { max: 1280, min: 1024 },
      items: 3,
      slidesToSlide: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 768 },
      items: 2,
      slidesToSlide: 1
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1
    }
  };

  return (
    <Carousel
      responsive={responsive}
      infinite={true}
      containerClass="carousel-container"
      removeArrowOnDeviceType={[]}
      deviceType="desktop"
      itemClass="carousel-item-padding"
      arrows={true}
    >
      {items.map(({ title, description, icon }, index) => (
        <div
          key={index}
          className="flex flex-col items-center p-6 text-center px-4 sm:w-auto"
        >
          <div className="mask mask-blob-1-1 relative flex aspect-[4/5] h-[284px] w-[284px] items-center justify-center bg-ph-sage">
            {icon}
          </div>
          <Text className="mt-6" color="dark-green" variant="h7a">
            {title}
          </Text>
          <Text className="mt-6" variant="p2a">
            {description}
          </Text>
        </div>
      ))}
    </Carousel>
  );
};

export default ItemCarousel;
